<template>
  <b-modal id="closing-months-modal" ref="closing-months-modal" :title="$t('broadcast.closingMonths')" hide-footer @shown="loadProps()" @hidden="clearProps()">
    <SpinnerLoader v-if="isLoadingModal" :loading="isLoadingModal === true ? 'loading' : 'success'" />
    <template v-else>
      <MultiSelect
        v-model="selectedYear"
        :options="years"
        :placeholder="$t('channelModal.pickYear')"
        label="id"
        track-by="id"
        :allow-empty="false"
        @input="selectedYearFilter()"
      ></MultiSelect>
      <div class="months-grid mt-3">
        <b-button
          v-for="(mn, index) in monthsList"
          :key="mn.id"
          :variant="broadcastClosedMonths[index] && broadcastClosedMonths[index].is_closed ? 'primary' : 'outline-secondary'"
          :title="broadcastClosedMonths[index] && broadcastClosedMonths[index].is_closed ? $t('broadcast.openMonth') : $t('broadcast.closeMonth')"
          size="sm"
          :disabled="isBusy || !broadcastClosedMonths[index]"
          @click="toggleMonth(broadcastClosedMonths[index])"
        >
          <b-icon :icon="broadcastClosedMonths[index] && broadcastClosedMonths[index].is_closed ? 'lock' : 'unlock'"></b-icon>
          {{ monthsList[index].name }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import MultiSelect from '@/components/MultiSelect';
import errorsHandler from '@/utils/errorsHandler';
import SpinnerLoader from '@/components/SpinnerLoader';

export default {
  name: 'ModalClosingMonths',
  components: { MultiSelect, SpinnerLoader },
  props: {
    broadcastStartDate: { type: String, default: undefined },
  },
  data() {
    return {
      isLoadingModal: true,
      selectedYear: '',
      isBusy: false,
    };
  },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
      isLocale: 'isLocale',
      years: 'getYear',
      broadcastClosedMonths: 'getBroadcastClosedMonths',
    }),
    monthsList() {
      return [
        { id: 1, name: this.$i18n.t('month.jan') },
        { id: 2, name: this.$i18n.t('month.feb') },
        { id: 3, name: this.$i18n.t('month.mar') },
        { id: 4, name: this.$i18n.t('month.apr') },
        { id: 5, name: this.$i18n.t('month.may') },
        { id: 6, name: this.$i18n.t('month.jun') },
        { id: 7, name: this.$i18n.t('month.jul') },
        { id: 8, name: this.$i18n.t('month.aug') },
        { id: 9, name: this.$i18n.t('month.sep') },
        { id: 10, name: this.$i18n.t('month.oct') },
        { id: 11, name: this.$i18n.t('month.nov') },
        { id: 12, name: this.$i18n.t('month.dec') },
      ];
    },
  },
  mounted() {},
  destroyed() {
    this.$store.commit('clearBroadcastClosedMonths');
  },
  methods: {
    async loadProps() {
      if (this.years.length < 1) await this.$store.dispatch('GET_YEAR');
      this.selectedYear = this.broadcastStartDate ? { id: +this.broadcastStartDate.slice(0, 4) } : { id: new Date().getFullYear() };
      await this.loadClosedMonths();
      this.isLoadingModal = false;
    },

    async loadClosedMonths() {
      await this.$store.dispatch('GET_BROADCAST_CLOSED_MONTHS', { data: { year: this.selectedYear?.id } });
    },

    async selectedYearFilter() {
      await this.loadClosedMonths();
    },

    async toggleMonth(monthProp) {
      if (!monthProp) return;
      const modalResult = await this.$bvModal
        .msgBoxConfirm(
          monthProp.is_closed
            ? `${this.$i18n.t('table.confirmOpen')} ${this.monthsList[monthProp.month_id - 1]?.name}?`
            : `${this.$i18n.t('table.confirmClose')} ${this.monthsList[monthProp.month_id - 1]?.name}? ${this.$i18n.t('table.allDataWillBecomeFact')}`,
          {
            title: monthProp.is_closed ? this.$i18n.t('broadcast.openMonth') : this.$i18n.t('broadcast.closeMonth'),
            size: 'sm',
            okTitle: this.$i18n.t('table.yes'),
            cancelTitle: this.$i18n.t('table.no'),
            autoFocusButton: 'ok',
            id: 'confirm-open-close-month-modal',
          }
        )
        .catch((err) => {});
      if (!modalResult) return;
      this.isBusy = true;
      const { month_id, year_id, is_closed } = monthProp;
      await this.$store.dispatch('POST_OPEN_CLOSE_MONTH', {
        formData: {
          month: month_id,
          year: year_id,
          is_closed: !is_closed,
        },
        handler: (res) => {
          this.$notify({
            type: 'success',
            title: is_closed ? this.$i18n.t('alert.openedMonth') : this.$i18n.t('alert.closedMonth'),
          });
          this.loadClosedMonths();
          const startOfCurrentWeek = new Date(this.broadcastStartDate).getTime();
          const WEEK_IN_MILLISECONDS = 604800000;
          const endOfCurrentWeek = startOfCurrentWeek + WEEK_IN_MILLISECONDS;
          const monthString = new Date(year_id, month_id - 1, 1);
          const calendarDateFrom = monthString.getTime();
          const monthTo = new Date(monthString.getFullYear(), monthString.getMonth() + 1, 0);
          const calendarDateTo = new Date(monthTo).getTime();
          if (
            (calendarDateFrom <= startOfCurrentWeek && calendarDateTo >= startOfCurrentWeek) ||
            (calendarDateFrom >= startOfCurrentWeek && calendarDateFrom <= endOfCurrentWeek)
          )
            this.$emit('update');
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
      this.isBusy = false;
    },

    clearProps() {
      this.isLoadingModal = true;
    },
  },
};
</script>

<style lang="sass">
.months-grid
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  gap: 0.5rem
</style>
